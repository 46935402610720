<template>
    <div>
        <top-layer></top-layer>
        <div class="main">
            <center-layer style="top:-520px"></center-layer>
            <div class="container">
                <div class="container-top">
                    <div class="container-top-box">
                        <div class="left-box">
                            <div class="image">
                                <img src="../image/shipper-mp-code.jpg">
                            </div>
                            <p class="left-box-sao">微信扫码进行体验</p>
                        </div>
                        <div class="title">拉货找车、发布货源、在线车库、货运保险、车辆定位...</div>
                    </div>
                    <div class="container-top-box">
                        <div class="left-box">
                            <div class="image">
                                <img src="../image/driver-mp-code.jpg">
                            </div>
                            <p class="left-box-sao">微信扫码进行体验</p>
                        </div>
                        <div class="title">查找货源、针对司机端推出“齐心帮”小程序，服务涵盖</div>
                    </div>
                </div>
                <div class="container-bottom">
                    <div class="bottom-title">
                        平台合作流程
                    </div>
                    <div class="bottom-content">
                        <div>></div>
                        <div>
                            <p>货主发布货物需求</p>
                        </div>
                        <div></div>
                        <div>
                            <p>货主与司机进行对接洽谈时间金额等详情</p>
                        </div>
                        <div></div>
                        <div>
                            <p>双方确认送货方案及报价</p>
                        </div>
                        <div></div>
                        <div>
                            <p>鉴定协议预定款项（线下交易不预付）</p>
                        </div>
                        <div></div>
                        <div>
                            <p>双方状态交互确认</p>
                        </div>
                        <div></div>
                        <div>
                            <p>完成订单交付货款</p>
                        </div>
                        <div></div>
                        <div>
                            <p>查看或评价订单</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bottom-layer></bottom-layer>
    </div>
</template>
<script>
import topLayer from '../components/top.vue'
import bottomLayer from '../components/bottom.vue'
import centerLayer from '../components/center.vue'
export default {
    components: {
        topLayer,
        bottomLayer,
        centerLayer
    }

}
</script>
<style lang="css" scoped>
.main {
    height: 100%;
    width: 100%;
    /* background: wheat; */
    position: relative;
    background: url(../image/banner.jpg) no-repeat;
    background-size: 100% 700px;
    padding-top: 520px;
    box-sizing: border-box;
}
.container {
    width: 100%;
    /* position: absolute; */
    padding: 100px 15% 0 15%;
    box-sizing: border-box;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    background: white;
}
.container .container-top {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 100px;
}
.container .container-top .container-top-box {
    /* width: 40%; */
    height: 100%;
}
.container .container-top .container-top-box .title {
    text-align: left;
    margin-top: 20px;
    width: 300px;
    font-weight: bold;
}
.container .container-top .container-top-box .left-box {
    height: 300px;
    width: 300px;
    border: 1px solid transparent;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 2px 2px 10px gray;
}
.container-top-box .left-box .left-box-sao {
    display: block;
    width: 70%;
    line-height: 40px;
    height: 40px;
    border-radius: 50px;
    color: blue;
    border: 2px solid;
    font-size: 16px;
    font-weight: bold;
}
/* .container .container-top .container-top-box {

    width: 40%;
    background: wheat;
} */
.container .container-bottom {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.container .container-bottom .bottom-title {
    font-size: 32px;
    text-align: center;
    color: rgba(3, 93, 252, 1);
    font-weight: bold;
    position: relative;
}
.container .container-bottom .bottom-title::before {
    content: '';
    width: 60px;
    height: 4px;
    background: rgba(3, 93, 252, 1);
    position: absolute;
    bottom: -10px;
    left: calc(50% - 30px);

}
.container-bottom .bottom-content {
    margin-top: 100px;
    height: 400px;
    width: 100%;
    border: 2px dashed gray;
    border-radius: 50px;
    border-left: none;
    position: relative;
}
.container-bottom .bottom-content div {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 4px solid rgba(3, 93, 252, 1);
    background: white;
    text-align: center;
    line-height: 50px;
    font-size: 32px;
}
.container-bottom .bottom-content div p {
    height: 50px;
    width: 150px;
    position: absolute;
    left: -40px;
    top: 50px;
    line-height: normal;
    font-size: 16px;
}
.container-bottom .bottom-content div:nth-child(1) {
    left: 0;
    top: -25px;
}
.container-bottom .bottom-content div:nth-child(2) {
    height: 60px;
    width: 60px;
    left: 15%;
    top: -30px;
    background: rgba(3, 93, 252, 1);
}
.container-bottom .bottom-content div:nth-child(3) {
    left: 35%;
    top: -25px;
}
.container-bottom .bottom-content div:nth-child(4) {
    height: 60px;
    width: 60px;
    left: 50%;
    top: -30px;
    background: rgba(3, 93, 252, 1);
}
.container-bottom .bottom-content div:nth-child(5) {
    right: 30%;
    top: -25px;
}
.container-bottom .bottom-content div:nth-child(6) {
    height: 60px;
    width: 60px;
    right: 10%;
    top: -30px;
    background: rgba(3, 93, 252, 1);
}
.container-bottom .bottom-content div:nth-child(7) {
    right: -30px;
    top: 45%;
}
.container-bottom .bottom-content div:nth-child(8) {
    height: 60px;
    width: 60px;
    right: 10%;
    bottom: -30px;
    background: rgba(3, 93, 252, 1);
}
.container-bottom .bottom-content div:nth-child(9) {
    right: 25%;
    bottom: -30px;
}
.container-bottom .bottom-content div:nth-child(10) {
    height: 60px;
    width: 60px;
    right: 40%;
    bottom: -30px;
    background: rgba(3, 93, 252, 1);
}.container-bottom .bottom-content div:nth-child(11) {
    left: 42%;
    bottom: -30px;
}
.container-bottom .bottom-content div:nth-child(12) {
    height: 60px;
    width: 60px;
    left: 30%;
    bottom: -30px;
    background: rgba(3, 93, 252, 1);
}.container-bottom .bottom-content div:nth-child(13) {
    left: 15%;
    bottom: -30px;
}
.container-bottom .bottom-content div:nth-child(14) {
    height: 60px;
    width: 60px;
    left: 0%;
    bottom: -30px;
    background: rgba(3, 93, 252, 1);
}
.image {
    height:200px;width:200px;
    border-raduis:20px;
    /*background: url(../image/code.png) no-repeat;*/
    /*background-size: 100% 100%;*/
}
.image  img{
    width:100%;
}
</style>
